import { CIcon } from '~/icons/types'

export const ciBookmark: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M17.7 2.6c-3.7-.4-7.6-.4-11.4 0-1.4.1-2.5 1.4-2.5 2.9V21c0 .3.1.5.4.6.2.1.5.1.7 0l7.2-3.6 7.2 3.6c.1.1.2.1.3.1.1 0 .3 0 .4-.1.2-.1.4-.4.4-.6V5.5c-.2-1.5-1.3-2.8-2.7-2.9Zm1.1 17.2-6.4-3.2c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3.1l-6.4 3.2V5.5c0-.7.5-1.4 1.2-1.4 3.6-.4 7.4-.4 11 0 .7.1 1.2.7 1.2 1.4v14.3Z',
      fill: '#000'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'bookmark',
  type: 'light'
}
