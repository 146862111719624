import { render, staticRenderFns } from "./HeaderUser.vue?vue&type=template&id=5c49ca43&"
import script from "./HeaderUser.vue?vue&type=script&lang=ts&"
export * from "./HeaderUser.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports