var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'tw-bg-white tw-shadow tw-rounded-xl tw-p-4 tw-min-w-72': !_vm.inPage,
    'tw-max-w-96 tw-px-4 tw-mx-auto': _vm.inPage
  }},[(_vm.isAnon)?_c('div',[_c('h3',{staticClass:"tw-text-body tw-text-lg tw-font-semibold tw-text-center tw-py-3 tw-mb-0 first-letter:tw-capitalize",class:{ '!tw-my-4': _vm.inPage }},[_vm._v("\n      "+_vm._s(_vm.$t('sign in to {site}', {
          site: _vm.siteDomain
        }))+"\n    ")]),_vm._v(" "),_c('CLink',{staticClass:"tw-flex tw-items-center tw-justify-center tw-gap-2 tw-bg-primary tw-text-white hover:tw-bg-primary-600 hover:tw-text-white focus-visible:tw-bg-primary-600 focus-visible:tw-text-white tw-h-10 tw-px-4 tw-rounded-lg tw-text-base tw-font-medium tw-text-center tw-transition-colors",attrs:{"to":{ name: '__login' }},on:{"click":function($event){return _vm.$emit('click')}}},[_c('CIcon',{attrs:{"icon":_vm.ciUserCircleSolid,"size":"20"}}),_vm._v("\n      "+_vm._s(_vm.$t('login')))],1),_vm._v(" "),_c('hr',{staticClass:"!tw-mt-4 !tw-mb-0"}),_vm._v(" "),_c('h3',{staticClass:"tw-text-body tw-text-lg tw-font-semibold tw-text-center tw-py-3 tw-mb-0"},[_vm._v("\n      "+_vm._s(_vm.$t('no account?'))+"\n    ")]),_vm._v(" "),_c('CLink',{staticClass:"tw-flex tw-items-center tw-justify-center tw-gap-2 tw-text-primary hover:tw-bg-primary-500 hover:tw-text-white focus-visible:tw-bg-primary-500 focus-visible:tw-text-white tw-border tw-border-solid tw-border-primary tw-h-10 tw-px-4 tw-rounded-lg tw-text-base tw-font-medium tw-text-center tw-transition-colors",class:{ 'tw-bg-white': !_vm.inPage },attrs:{"to":{ name: '__guest_register' }},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v("\n      "+_vm._s(_vm.$t('register_for_free')))]),_vm._v(" "),_c('hr',{staticClass:"!tw-mt-4 !tw-mb-0"}),_vm._v(" "),_c('CLink',{staticClass:"tw-relative tw-flex tw-items-center tw-gap-3 tw-text-body tw-text-base tw-font-medium tw-h-10 tw-px-4 hover:tw-bg-grey-100 hover:tw-text-blue-500 tw-transition-colors tw-rounded-lg tw-whitespace-nowrap",attrs:{"to":"/classifieds/my/"},on:{"click":function($event){_vm.$emit('click', {
          link: {
            title: _vm.$t('my classified'),
            url: '/classifieds/my/'
          },
          event: $event
        })}}},[_c('CIcon',{attrs:{"icon":_vm.ciFolderOpen,"size":"24"}}),_vm._v("\n      "+_vm._s(_vm.$t('my classified'))+"\n    ")],1),_vm._v(" "),_c('CLink',{staticClass:"tw-relative tw-flex tw-items-center tw-gap-3 tw-text-body tw-text-base tw-font-medium tw-h-10 tw-px-4 hover:tw-bg-grey-100 hover:tw-text-blue-500 tw-transition-colors tw-rounded-lg tw-whitespace-nowrap",attrs:{"to":{ name: '__parking' }},on:{"click":function($event){_vm.$emit('click', {
          link: {
            title: _vm.$t('parked::parking_btn'),
            url: _vm.$router.resolve({ name: '__parking' }).href
          },
          event: $event
        })}}},[_c('CIcon',{attrs:{"icon":_vm.ciParking,"size":"24"}}),_vm._v("\n      "+_vm._s(_vm.$t('parked::parking_btn'))+"\n    ")],1),_vm._v(" "),_c('CLink',{staticClass:"tw-relative tw-flex tw-items-center tw-gap-3 tw-text-body tw-text-base tw-font-medium tw-h-10 tw-px-4 hover:tw-bg-grey-100 hover:tw-text-blue-500 tw-transition-colors tw-rounded-lg tw-whitespace-nowrap",attrs:{"to":{ name: '__searches' }},on:{"click":function($event){_vm.$emit('click', {
          link: {
            title: _vm.$t('searches'),
            url: _vm.$router.resolve({ name: '__searches' }).href
          },
          event: $event
        })}}},[_c('CIcon',{attrs:{"icon":_vm.ciBookmark,"size":"24"}}),_vm._v("\n      "+_vm._s(_vm.$t('searches'))+"\n    ")],1)],1):_c('div',{staticClass:"tw-grid tw-grid-cols-1 tw-gap-4"},[(_vm.isAdmin)?_c('HeaderUserMenuAdminList',{on:{"click":function($event){return _vm.$emit('click')}}}):[_c('CLink',{staticClass:"tw-flex tw-items-center tw-gap-4",attrs:{"to":{ name: '__account' }}},[_c('CAvatar',{attrs:{"avatar":_vm.avatar,"title":_vm.username,"editable":false,"width":40,"height":40,"clickable":false}}),_vm._v(" "),_c('span',{staticClass:"tw-text-body tw-text-lg tw-font-semibold tw-truncate"},[_vm._v(_vm._s(_vm.username))])],1),_vm._v(" "),(_vm.showCredits)?_c('HeaderCredits',{on:{"click":function($event){return _vm.$emit('click')}}}):_vm._e(),_vm._v(" "),_c('HeaderUserMenuList',{on:{"click":function($event){return _vm.$emit('click', $event)}}})],_vm._v(" "),_c('a',{staticClass:"tw-bg-red-400 tw-text-white hover:tw-bg-red-500 hover:tw-text-white focus-visible:tw-bg-red-500 focus-visible:tw-text-white tw-p-2 tw-rounded-lg disabled:tw-opacity-90 tw-text-center tw-text-base tw-font-medium",attrs:{"aria-label":"logout","href":_vm.userLogoutLink}},[_vm._v("\n      "+_vm._s(_vm.$t('logout'))+"\n    ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }